.footer{
    font-family: 'Inter', sans-serif;
    background-color: #263238;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem 7rem;
}

.logo_footer{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1.5rem;
}    

.footer_logoImage{
    width: 2rem;
}

.footer_logoText{
    width: 6em;
}

.footerInfo > p{
    color: #F5F7FA;
    font-size: 0.7rem;
    line-height: 1.2rem;
}

.nossosValores > strong{
    font-weight: 600;
    display: block;
    margin-bottom: 1em;
}

.nossosValores > ul{
    list-style-type: none;
    font-size: 0.7rem;
    line-height: 1.6rem;
}

.contato{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contato > strong{
    font-weight: 600;
}

.buttonsContatos{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.buttonsContatos > a{
    background-color: transparent;
    border: 0;
}

.buttonsContatos > a > img{
    width: 2rem;
}

@media screen and (max-width: 480px){
    .footer{
        padding: 2rem;
    }
}

@media screen and (max-width: 800px){
    .nossosValores{
        display: none;
    }
}