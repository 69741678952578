.row {
  display: grid;
  align-items: center;
  grid-template-columns: 0.25fr repeat(3, 1fr);
  justify-items: center;
  width: 100%;
  border-radius: 4px;
  position: relative;
}

.row span {
  max-width: 180px;
  word-break: auto-phrase;
  text-align: center;
}
.row span:hover {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--primary);
}

.row span:first-child {
  justify-self: flex-start;
  text-align: start;
  padding-left: 2rem;
}
.row span:last-child {
  justify-self: flex-end;
}

.checkbox {
  position: absolute;
  right: -2rem;
}
