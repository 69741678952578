.container {
  font-family: 'Inter';
  font-size: 1.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.actions {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.observation {
  margin-top: 1rem;
}

.observation span {
  font-size: 1rem;
  font-weight: 500;
}

.warning {
  font-weight: 500;
  color: #9d1816;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.9rem;
  margin-top: 1rem;
}
