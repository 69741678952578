.item {
  display: grid;
  grid-template-columns: 2fr 2fr 1.5fr 1.45fr;
  list-style: none;
  align-items: center;
  background: #d9d9d9;
  font-size: 0.875rem;
  font-weight: 700;
  padding-left: 2rem;
  margin-bottom: 0.25rem;
}
.item.answered {
  padding: 2rem 0;
}
.row-field {
  text-align: center;
}
