.container {
  display: flex;
  flex-direction: column;
  padding: 3% 8% 0;
  gap: 2%;
  height: 100%;
  
}

.logo {
  width: 20%;
  height: 13%;
}

.logo img {
  width: 100%;
  height: 100%;
}

.title{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 7px;
}

.title h1{
  color: #007a37;
  text-align: center;
  font-size: 1.7rem;
  font-family: Inter;
  font-weight: 700;
}

.title p{
  color: #000000;
  text-align: center;
  font-size: 0.9rem;
  font-family: Inter;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 0 2%;

}

.form h2 {
  font-weight: 700;
  font-size: 0.9rem;
  color: #000000; 
  font-family: Inter;
  font-weight: 600;
}
.form div {
  width: 100%;
}
.form div span{
  color: red;
}
.formInput {
  padding-left: 2rem;
  width: 100%;
  height: 30px;
  border: none;
}

.formInput::placeholder {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
}

.formBtn{
  width: 100%;
  background-color: #0B5226;
  color: #ffff;
  padding: 0.6rem;
  border-radius: 7.56px;
  border: none;
  font-family: Bebas Neue;
}

.overlayModalSucess {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #00000080;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.contentModalSucess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 350px;
  max-height: 90vh;
  background: #ffff;
  border-radius: 10px;
  padding-bttom:4%;
  position: relative;   
}

.contentModalSucess > .header {
  border-top: 8px solid var(--primary);
  border-radius: 9px;
  display: flex;
  align-items: flex-start;
  color: var(--primary);
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  position: relative;  
  top:-1px; 
}

.header h2 {
  align-self: flex-start;
  text-align: center;
  flex: 1;
  font-family: 'Bebas Neue';
  font-weight: 700;
  font-size: 2rem;
  z-index: 2;
  margin-top: 1.4rem;
}

.header img {
  width: 15%;
  z-index: 2;
}

.header .pattern {
  position: absolute;
  top: -7px;
  left: 5px;
  width: 55%;
  z-index: 1;
}

.header button {
  all: unset;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 10;
}

.body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 80%;
  margin-bottom: 2rem;
}

.body p {
  text-align: center;
  font-size: 1rem;
  font-family: inter;
  font-weight: 700;
  text-transform: uppercase;
}

.body button {
  align-self: center;
  padding: 0.5rem 0.6rem;
  border-radius: 7.56px;
  border: none;
  cursor: pointer;
  background-color: #0B5226;  
}

.body button a {
  color: #ffff;
  text-decoration: none;
  font-family: Bebas Neue;
  font-size: 1.1rem;
  letter-spacing: 1px;
}


@media screen and (max-width: 480px) {
  .contentModalSucess {
    width: 85%;
  }
}

@media screen and (min-width: 1440px) {
  .contentModalSucess {
    width: 476px;
  }
}


@media (max-width: 320px) {
  .logo {
    margin-left: 5px;
  }
}