.container{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.column {
  text-align: center;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.column:first-child {
  justify-content: flex-start;
  font-weight: 600;
}
.column:nth-child(2) {
  font-weight: 600;
}

.column:last-child {
  justify-content: flex-end;
  padding-right: 2rem;
}

.part-details-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
}

.part-details-list li {
  list-style: none;
  max-width: 100%;
  font-size: 0.85rem;
}

.requested-quantity {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.offer-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
  border-radius: 8px;
  background: #e7f3e842;
}
.offer-container-sub-row{
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.offer-container h3 {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  justify-items: stretch;
  align-items: stretch;
}
.row input {
  font-family: 'Inter';
  font-size: 0.85rem;
  border-radius: 4px;
  border-color: 2px solid (--grey);
  text-align: center;
}
.describe {
  font-size: 0.85rem;
}

.describe > svg {
  rotate: -90deg;
}

.row .input-control:first-child {
  flex: 1;
}
.input-control {
  display: flex;
  flex-direction: column;
}
.input-control span {
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Inter';
  display: flex;
  align-items: flex-end;
  gap: 0.25rem;
}

.input-control span sub {
  color: var(--grey);
}

.offer-reference-sub-row{
  display: none;
}

.space-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 .5rem;
}

@media only screen and (max-width: 480px) {
  .quote-item > div:nth-of-type(1){
    grid-template-columns: 0.5fr 2fr 1.5fr!important;
    justify-items: center!important;
    border-radius: 5px;
    padding: 0;
    span {
      font-family: 'Inter';
      font-weight: 700;
      font-size: 1rem;
    }
  }
  .describe {
    font-size: 1rem!important;
  }
  .quote-item,
  .offer-container{
    background-color: #a5d6a7;
    border-radius: 5px;
  }
  .offer-reference-sub-row{
    display: flex;
    flex-direction: row;
    background-color: #e8f5e9;
    border-radius: 5px;
    padding: 1.5rem 1rem;
    margin-top: 1rem;

    b{
      font-family:'Inter', sans-serif;
    }
  }
  .offer-container-sub-row{
    background-color: #e8f5e9;
    border-radius: 5px;
  }
  .offer-container-sub-row{
    padding: 1rem;
  }
  .offer-container{
    padding: 0 1rem 1rem;
  }
  .row .input-control:nth-child(2) {
    flex: 1;
  }
}
