.wrapper {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}
.content {
  width: 95%;

  margin-top: 1rem;

  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

@media screen and (max-width: 480px) {
  .wrapper {
    max-height: 60vh;
  }
  .content {
    overflow-y: scroll;
  }
}
