.input-control {
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  z-index: 1;
  position: relative;
}

.input-control label {
  font-weight: 600;
  font-size: 1.25rem;
  font-family: 'Inter', sans-serif;
}

.input-icon-container {
  position: relative;
  width: 100%;
}
.input-icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
}
.input-icon button {
  all: unset;
  cursor: pointer;
}

.input {
  border: 2px solid var(--l_grey);
  border-radius: 8px;
  background-color: var(--silver);
  height: 2.5rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
}
