.textarea-control {
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  z-index: 1;
}
.textarea-control textarea:disabled {
  opacity: 1;
  resize: none;
}

.textarea-control label {
  font-weight: 600;
  font-size: 1.25rem;
  font-family: 'Inter', sans-serif;
}

.description {
  font-size: 0.75rem;
}

.textarea {
  border: 2px solid var(--l_grey);
  border-radius: 8px;
  background-color: var(--silver);
  height: 2.5rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
  height: 6rem;
}
