.input-image-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.input-image-control {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.input-image-control label {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0;
}

.input-image-control input {
  display: none;
}

.preview-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}
.preview-wrapper .image-item {
  all: unset;
  cursor: pointer;
  width: 5.5rem;
  height: 5.5rem;
}
.preview-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.img-field {
  all: unset;
  display: flex;
  cursor: pointer;
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 4px;
  border: 2px solid var(--primary);
  align-items: center;
  justify-content: center;
}

.containerImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.7rem;
  position: relative;
}
.containerImage > button:nth-of-type(2) {
  display: none;
  font-size: 1rem;
  padding: 0.1rem 0.5rem;
}

.containerImage:hover > button:nth-of-type(2) {
  display: block;
}

@media only screen and (max-width: 480px) {
  .containerImage > button:nth-of-type(2) {
    position: absolute;
    top: -8px;
    right: -10px;
    display: flex !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 0.8rem;
    border: none;
    background-color: transparent;
    svg {
      position: absolute;
    }
  }
}
