.vehicle-info {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  gap: 1rem;
}
.vehicle-info input {
  font-weight: 700;
}

.row {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}
.row:first-child,
.row:last-child {
  display: grid;
  grid-template-columns: 60% 35%;
  justify-content: space-between;
}
