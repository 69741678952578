.container {
  margin-bottom: 1.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid;
}

.subtotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e8f5e9;
  padding: 0.5rem 2rem;
  border-radius: 8px;
  font-family: 'Inter';
  font-weight: 600;
}
.subtotal span:last-child {
  font-weight: 800;
  color: var(--primary);
}
.observation {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  font-family: 'Inter';
  margin-top: 0.8rem;
}
.observation h1 {
  font-size: 1rem;
  font-weight: 600;
}

.observation h1 span {
  font-weight: 400;
  color: var(--grey);
}

.headingWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  gap: 0.5rem;
}

.info {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-bottom: 1rem;
}

.info > li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  list-style: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0 0.25rem;
}

.info > li:nth-of-type(2) {
  justify-content: flex-end !important;
}

.subTitle {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.subTitle > li:nth-of-type(1) {
  background-color: #2194f3;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  border-radius: 27px;
  padding: 0.3rem;
  box-shadow: 0px 2.79px 2.79px 0px #00000040;
}
.subTitle > li:nth-of-type(2) {
  background-color: #fbc02d;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  border-radius: 27px;
  padding: 0.3rem;
  box-shadow: 0px 2.79px 2.79px 0px #00000040;
}
.subTitle > li {
  gap: 1rem;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  margin-top: 1rem;
}

.item-description {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  background: #eee;
  padding: 0.5rem 1rem;
  font-weight: 700;
}
.item-description span:last-child {
  text-align: center;
}

.items-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
  background-color: #a5d6a7;
}
.list-header {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  background: #81c784;
  padding: 0.5rem 1rem;
  font-weight: 700;
}
.list-header span:last-child {
  text-align: center;
}

.subtotal-info {
  display: flex;
  flex-direction: column;
}

.subtotal-info span {
  color: var(--error) !important;
  font-size: 0.75rem;
  margin-left: 0.25rem;
}
