.vehicle-info {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  gap: 1rem;
}

.row {
  display: grid;
  gap: 1rem;
  align-items: center;
}
.row:nth-child(1) {
  grid-template-columns: 25% 35% 35%;
}
.row:nth-child(2) {
  grid-template-columns: 25% 75%;
}

.table-heading {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.section-title {
  font-size: 1.25rem;
}

.label {
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
}

.label span {
  display: flex;
  align-items: flex-end;
  color: var(--grey);
  font-weight: 600;
  font-size: 0.85rem;
  padding-left: 1rem;
}

.companies-row {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}

.companies-row li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--tint_5);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: 'Inter';
}

.part-details-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  background: #d9d9d9;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
}

.part-details-list li {
  display: flex;
  flex-direction: row;
  list-style: none;
  max-width: 100%;
  font-size: 0.85rem;
}
/* workshops list */

.workshops-list {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.workshops-list li {
  list-style: none;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  background: #e1e5e9;
  text-align: center;
}

/* banners list */

.banners-list {
  display: flex;
  align-items: center;
  gap: 1rem;
  overflow: scroll;
  padding-top: 1rem;
}

.banners-list::-webkit-scrollbar {
  display: none;
}

.banners-list li {
  list-style: none;

  aspect-ratio: 16 / 9;
  width: 250px;
  height: 250px;
  border-radius: 4px;
}

.banners-list li button {
  all: unset;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.banners-list li button:hover {
  background: var(--tint_5);
  border-radius: 8px;
  transform: scale(1.1);
}

.banners-list li button img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
