.container {
  all: unset;
  cursor: pointer;
  width: 100%;
}
.container * {
  font-family: Arial;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr repeat(5, 1fr);
  justify-items: center;
  align-content: center;
  align-items: center;
  padding: 1rem 0.75rem;
  height: 3.5rem;
  background: #f5faf5;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}
.row:nth-child(odd) {
  background: #fff;
}
.row > div {
  width: 100%;
  text-align: center;
  position: relative;
}

.text-left {
  text-align: left;
}

.row.selected {
  background: var(--tint_5);
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  transition: color ease 300ms;
}
.row.selected .dropdown .icon {
  transform: rotateX(180deg);
}
.subrow {
  background: var(--tint_5);
  padding: 1rem 0.75rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  backdrop-filter: blur(5px);
}

@media screen and (max-width: 480px) {
  .row {
    grid-template-columns: repeat(3, 1fr);
    padding: 1.5rem 0;
  }
  .subrow {
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: flex-start;
    width: 100%;
    overflow-x: auto;
  }
  .subrow::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }
  .subrow::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #f0f0f0;
  }
  .subrow button {
    font-size: 0.8rem;
  }
}
