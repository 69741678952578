.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 1rem;
}

.label {
  font-weight: 600;
  font-size: 1.25rem;
  font-family: 'Inter', sans-serif;
  width: 100%;
}

.wrapper {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.wrapper button {
  all: unset;
  cursor: pointer;
}

.wrapper img {
  width: 96px;
  aspect-ratio: 1;
}
