.vehicle-info {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  gap: 1rem;
}

.row {
  display: grid;
  gap: 1rem;
  align-items: center;
}
.row:nth-child(1) {
  grid-template-columns: 25% 35% 35%;
}
.row:nth-child(2) {
  grid-template-columns: 25% 75%;
}

.table-heading {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.section-title {
  font-size: 1.25rem;
}

.label {
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
}

.label span {
  display: flex;
  align-items: flex-end;
  color: var(--grey);
  font-weight: 600;
  font-size: 0.85rem;
}

.companies-row {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}

.companies-row li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--tint_5);
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: 'Inter';
}

.observations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
