.header {
  display: grid;
  align-items: center;
  grid-template-columns: 0.25fr repeat(3, 1fr);
  justify-items: center;
  width: 100%;
  background: var(--tint_3);
  padding: 0.25rem 2rem;
  border-radius: 4px;
  max-height: 2.5rem;
  height: 2.5rem;
}
.header span {
  font-weight: 700;
  font-size: 0.85rem;
  max-width: 200px;
  text-align: center;
}
.header span:first-child {
  justify-self: flex-start;
  text-align: start;
}
.header span:last-child {
  justify-self: flex-end;
}
