.label {
  font-size: 1.15rem;
  font-weight: 600;
  font-family: 'Inter';
  width: 45%;
  border-bottom: 3px solid var(--l_grey);
  color: var(--l_grey);
  cursor: pointer;
}

.label.selected {
  color: var(--primary);
  border-color: var(--primary);
}
