.value-box.web {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.value-box .value {
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;

  border-radius: var(--radius-full, 62.4375rem);
  background: var(--primary);

  color: #fff;
  text-align: center;
  font-weight: 600;
  font-family: 'Montserrat';
}

.value-box b {
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  font-family: 'Montserrat';
}

.value-box.mobile {
  display: flex;
  padding: 0.5rem var(--00, 0rem);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
}
