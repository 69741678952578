#network-status {
  width: 100%;
  height: 50px;
  position: fixed;
  z-index: 10;
  color: #fff;
  font-weight: bold;
  display: none;
  align-items: center;
  padding: 0.5em;
  transition: all 0.5s ease-out;
}
#network-status p {
  margin-left: 10px;
}
#network-status.online {
  background-color: #6bb56a;
  display: flex;
}
#network-status.offline {
  background-color: #f54b4f;
  display: flex;
}
