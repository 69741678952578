.item-brand.web {
  display: flex;
  /* min-width: 40.8125rem; */
  width: 100%;
  height: 5.2rem;
  padding: 0.75rem 1rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  border-radius: 0.5rem;
  background: #e1e5e9;
  font-size: 1.125rem;
  overflow: hidden;
}

.item-brand.answered,
.item-brand.to-answer {
  padding-right: 0;
}

.box-right {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.order-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.answer-box {
  display: flex;
  padding: 0.875rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background: var(--tint-4, #c8e6c9);
}
.answer-box .input-box {
  display: flex;
  padding: 0rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
}

.to-answer .answer-box {
  display: flex;
  width: 10rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  align-self: stretch;
}

.to-answer .input-box {
  display: flex;
  padding: 0rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
}

.to-answer .input-box .input {
  border: none;
  display: flex;
  min-height: 3rem;
  width: 100%;
  padding: var(--2s-scale-8, 0.5rem) var(--2s-scale-12, 0.75rem);
  justify-content: flex-end;
  align-items: center;

  border-radius: 0.5rem;
  background: #e7e7e7;
}

.input-box .input::placeholder {
  color: var(--grey-blue, #abbed1);
  text-align: center;
}

.available-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
}

.available-quantity span {
  font-size: var(--text-2xs, 0.75rem);
  font-weight: 400;
  font-family: 'Montserrat';
}

.available-quantity span b {
  color: var(--secondary, #0b5226);
  font-size: 0.75rem;
  font-weight: 700;
  font-family: 'Montserrat';
}

.answer-box .answer {
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;

  border-radius: var(--radius-full, 62.4375rem);
  background: var(--primary);

  color: #fff;
  text-align: center;
  font-weight: 600;
}

.order-box b,
.answer-box b {
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  font-family: 'Montserrat';
}

.order-box .orders {
  display: flex;
  padding: 0.25rem 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--2s-scale-10, 0.625rem);

  border-radius: var(--radius-full, 62.4375rem);
  background: var(--primary, #007a37);

  color: #fff;
  text-align: center;
  font-weight: 600;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.info span {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 130%; /* 1.4625rem */
  color: var(--b_grey, #263238);
  font-family: 'Montserrat';
}

/* mobile */

.item-brand.mobile {
  display: flex;
  /* width: 22.875rem; */
  width: 100%;
  background: #000;
  padding: 0.5rem 1rem;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;

  border-radius: 0.5rem;
  background: #e1e5e9;
}

.item-brand.mobile .brand-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.item-brand.mobile .box-right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  align-self: stretch;
}

.item-brand.mobile .value-box,
.item-brand.mobile .order-box {
  display: flex;
  padding: 0.5rem var(--00, 0rem);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
}

.item-brand.mobile .box-right .answer-box {
  display: flex;
  padding: 0.5rem var(--00, 0rem);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  border-radius: 0.5rem;
}

.item-brand.mobile .answer-box .input-box {
  display: flex;
  padding: 0rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 0.5rem;
}

.item-brand.mobile .info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.item-brand.mobile.empty {
  display: flex;
  height: 7.8125rem;
  padding: 0.5rem 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
}
