:root {
  --black: #000000;
  --b_grey: #263238;
  --d_grey: #4d4d4d;
  --grey: #717171;
  --l_grey: #89939e;
  --grey-blue: #abbed1;
  --silver: #f5f7fa;
  --white: #ffffff;
  --primary: #007a37;
  --secondary: #0b5226;
  --info: #2194f3;
  --shade_1: #43a046;
  --shade_2: #388e3b;
  --shade_3: #237d31;
  --shade_4: #1b5e1f;
  --shade_5: #0b5226;
  --shade_6: #103e13;
  --tint_1: #66bb69;
  --tint_2: #81c784;
  --tint_3: #a5d6a7;
  --tint_4: #c8e6c9;
  --tint_5: #e8f5e9;
  --warning: #fbc02d;
  --error: #9d1816;
  --success: #2e7d31;

  --radius-full: 62.4375rem;
  --2s-scale-10: 0.625rem;
}

:focus {
  border-color: #007a37;
  outline-color: #007a37;
}

body {
  background-color: #ffffff;
}

h1,
h2,
h3 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input {
  /* for IOS */
  font-size: 1rem;
}
