.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  margin-top: 1rem;
  gap: 0.8rem;
  width: 100%;
}
.body {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}
.images-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 480px) {
  .container {
    width: 95%;
  }
}
