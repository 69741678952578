.input-control {
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  z-index: 1;
  position: relative;
}

.input-control label {
  font-weight: 600;
  font-size: 1.25rem;
  font-family: 'Inter', sans-serif;
}

.input-icon-container {
  position: relative;
  width: 100%;
}
.input-icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
}
.input-icon button {
  all: unset;
  cursor: pointer;
  background: var(--tint_4);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  border: 2px solid var(--l_grey);
  border-radius: 8px;
  background-color: var(--silver);
  height: 2.5rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
}

.searchList-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--silver);
  width: 100%;
  border: solid 1px var(--grey);
  border-top: none;
  max-height: 300px;
  overflow: auto;
}

.searchList-options li {
  list-style: none;
  color: #333;
  font-weight: 600;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
}
.searchList-options li:hover {
  background: var(--tint_5);
}
