.top-menu--button {
  all: unset;
  width: 100%;
  padding: 0.8rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: var(--white);
  color: var(--black);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  font-weight: 800;
}

.top-menu--button.selected {
  background: var(--primary);
  color: var(--white);
}
