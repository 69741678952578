.quote-item {
  /* background-color: #a5d6a7; */
  border-radius: 5px;
}

.quote-item button > div > svg:first-of-type {
  position: absolute;
  right: calc(5%);
  path {
    fill: var(--secondary);
  }
}
.quote-item button > div > span:first-of-type {
  align-items: center;
  display: flex;
}
.quote-item button > div > span:first-of-type > svg {
  margin-right: 5px;
}

.part-details-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
}

.part-details-list li {
  list-style: none;
  max-width: 100%;
  font-size: 0.85rem;
}

.requested-quantity {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* width: 30% !important; */
  padding-right: 1rem;
}

.wrapperSubRow {
  background-color: var(--tint_5);
  border-radius: 5px;
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  div:nth-child(1) {
    font-weight: 600;

    div input:checked + label::before {
      background-color: var(--shade_1);
      width: 1.9rem;
      height: 1.8rem;
      border: none;
    }
    div input + label::before {
      border: 1px solid var(--black);
      width: 1.9rem;
      height: 1.8rem;
    }
  }
}

.opacity {
  opacity: 0.4;
}

.Answer {
  align-items: normal !important;
  padding-left: 0rem !important;

  div:nth-child(1) {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-end !important;
    flex-wrap: wrap !important;

    div:nth-child(1) {
      width: auto !important;
      img {
        display: none !important;
      }
      b {
        font-weight: bold !important;
        font-size: 0.97rem !important;
      }
    }
    span:nth-of-type(1) {
      color: var(--black) !important;
      font-size: 1rem !important;
      font-weight: bold;
      font-family: 'Inter', sans-serif;
      padding-left: 2rem;
    }
  }
  div:nth-child(2) {
    font-weight: bold !important;
    span {
      color: var(--black) !important;
      font-size: 1rem !important;
    }
  }
}
.wrapperAmount {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;

  div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    align-items: flex-end;

    div:nth-child(1) {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      img {
        height: 25px;
        width: 25px;
      }

      b {
        font-size: 1.1rem;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
      }
    }

    div:nth-child(2) {
      width: 75%;
      input {
        height: 3rem;
        border-radius: 6.8px;
        outline: 1px solid var(--l_grey);
        border: 1px solid var(--l_grey);
        text-align: center;
      }
    }
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    font-size: 0.97rem;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    align-items: flex-end;

    span {
      color: var(--primary);
      font-weight: bold;
      font-size: 1.1rem;
      font-family: 'Inter', sans-serif;
    }
  }
}

.wrapperSubRow2 {
  width: 100%;
  background-color: var(--tint_5);
  border-radius: 5px;
  padding: 0.8rem 1rem;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  div:nth-child(2) > div {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  div:nth-child(2) > div > p {
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    font-size: 0.9rem;
  }

  div:nth-child(2) > div > b {
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    text-transform: capitalize;
    font-size: 1rem;
  }
}

.warning {
  color: var(--error);
  font-size: 0.9rem;
  word-break: normal !important;
}

@keyframes tremble {
  0% {
    left: 2px;
  }
  25% {
    right: 2px;
  }
  50% {
    left: 2px;
  }
  75% {
    right: 2px;
  }
  100% {
    right: 2px;
  }
}

.warningAnimation {
  position: relative;
  animation-name: tremble;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
}

.notOffer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Bebas Neue';
  font-size: 1.5rem;
  color: var(--error);
  width: 100%;
}
