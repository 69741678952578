.vehicle-info {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  gap: 1rem;
}

.row {
  display: grid;
  gap: 1rem;
  align-items: center;
}
.row:nth-child(1) {
  grid-template-columns: 25% 35% 35%;
}
.row:nth-child(2) {
  grid-template-columns: 25% 75%;
}

.label {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.table-header {
  display: flex;
  justify-content: space-between;

  padding: 0.1875rem 1.25rem 0.1875rem 0.75rem;
  align-items: center;
  gap: var(--2s-scale-32, 2rem);
  border-radius: 0.25rem;
  background: var(--Tint-3, #a5d6a7);
}

.table-header li {
  list-style: none;
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 130%; /* 1.4625rem */
}

.answer {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  gap: 0.75rem;
}
.answers-list {
  border-radius: 0.5rem;
  background: #eef0f2;
  box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, 0.25);
  padding-top: 0.55rem;
  padding-bottom: 1rem;
}

.answer-info {
  display: flex;
  justify-content: space-between;

  padding: 0.1875rem 1.25rem 0.1875rem 0.75rem;
  align-items: center;
  gap: var(--2s-scale-32, 2rem);
  margin-bottom: 1rem;
}
.answer-info li {
  list-style: none;
  text-align: center;
}
.answer-info--name {
  display: inline-flex;
  align-items: flex-start;
  gap: 1rem;
}
.answer-info li:last-child {
  width: 7.125rem;
  text-align: center;
}
.answers-description--list {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  gap: 0.75rem;
}

.answer-description {
  display: flex;
  margin: auto;
  width: 95%;
  padding: 0.75rem var(--2s-scale-16, 1rem);
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  background: #e1e5e9;
}

.answer-description--details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.answer-description--details b {
  margin-right: 0.5rem;
}

.answer-description--values {
  display: flex;
  align-items: center;
  gap: var(--2s-scale-32, 2rem);
  flex-wrap: wrap;
  justify-content: center;
}
.answer-description--values .quantity,
.answer-description--values .total {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.answer-description--values .label {
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
}

.answer-description--values .value {
  display: flex;
  padding: 0.25rem 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: var(--Radius-Full, 62.4375rem);
  background: var(--Primary, #007a37);

  color: #fff;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .answer-description--values {
    gap: 0.5rem;
  }
  .answer-description--values .value {
    font-size: 1rem;
  }
}
