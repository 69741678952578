.overlay {
  width: 15%;
  height: 100%;
}
.navbar {
  background: var(--white);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.navbar-options {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 2rem 0 2rem;
}

@media screen and (max-width: 480px) {
  .overlay {
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.25);
  }
  .navbar {
    height: 100%;
    width: 50%;
  }
}
