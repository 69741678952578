.input-control {
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  z-index: 1;
  position: relative;
}

.input-control label {
  font-weight: 600;
  font-size: 1.25rem;
  font-family: 'Inter', sans-serif;
}

.input-icon-container {
  position: relative;
  width: 100%;
}
.input-icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
}
.input-icon button {
  all: unset;
  cursor: pointer;
}

.input {
  border: 2px solid var(--l_grey);
  border-radius: 8px;
  background-color: var(--silver);
  height: 2.5rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
}

.input[type='time'] {
  width: 90px;
}

.input-image-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.input-image-control {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-image-control label {
  font-size: 1.2rem;
  font-weight: 600;
}

.input-image-control input {
  display: none;
}
.input-image-control .img-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  height: 2.5rem;
  background: var(--silver);
  font-size: 0.8rem;
  gap: 0.5rem;
}

.img-field span {
  white-space: nowrap;
  font-weight: 500;
  font-family: 'Inter';
}

.preview-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.preview-wrapper button {
  all: unset;
  cursor: pointer;
  width: 5.5rem;
  height: 5.5rem;
}
.preview-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
