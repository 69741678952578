.quantity-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
  background: #e7e7e7;
  color: var(--l_grey);
  border-top-right-radius: 4px;
  width: 135px;
}
.quantity-field.selected {
  background: var(--tint_4);
  color: var(--black);
}
.quantity-field label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.9rem;
  font-family: 'Inter';
}
.label-select {
  display: flex;
  align-items: flex-end;
  width: 90%;
}
.label-select input {
  border-radius: 4px;
  text-align: center;
}
.invalid.label-select input {
  border-color: var(--error);
}
.quantity-description {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
}
.quantity-description b {
  color: var(--l_grey);
  font-family: 'Inter';
  text-align: center;
  font-size: 0.75rem;
}
.selected b {
  color: var(--primary);
}

.warning {
  color: var(--error);
  font-size: 0.8rem;
  word-break: normal !important;
}

@keyframes tremble {
  0% {
    transform: rotate(-4deg);
  }
  25% {
    transform: rotate(4deg);
  }
  50% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.warningAnimation {
  animation-name: tremble;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
}
