.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000080;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 400px;
  max-height: 90vh;
  background: #ffff;
  border-radius: 15px;
  padding: 2% 0 4%;
}

.body {
  flex: 1;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .content {
    width: 90%;
  }
}
