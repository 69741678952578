.filter-container {
  display: flex;
  flex-direction: column;
}
.filters {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
}
.date-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .filter-container {
    padding: 0.5rem;
  }
  .filters {
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .date-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
