.brand-info {
  display: none;
}

.brand-info.mobile,
.brand-info.web {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.brand-info .info {
  display: flex;
  gap: 0.25rem;
}
.brand-info span {
  display: flex;
  gap: 0.5rem;
  font-family: 'Montserrat';

  text-align: center;
  line-height: 130%;
}
.brand-info .reference {
  text-transform: uppercase;
}
