.actions {
  display: flex;
  justify-content: center;
  width: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
