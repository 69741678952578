.container {
  background: var(--silver);
  font-family: 'Inter';
  border-radius: 8px;
  padding: 2rem 1rem;
}

.heading {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.heading h1 {
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
  font-family: 'Inter';
  font-weight: 600;
}

.info {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: stretch;
  width: 100%;
  margin-bottom: 1rem;
}
.info li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  list-style: none;
  background: var(--tint_5);
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.85rem;
  padding: 0 0.25rem;
  width: 125px;
}
.answer-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: auto;
}

.subtotal {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background: var(--tint_5);
  font-family: 'Inter';
  font-size: 1rem;
  line-height: 4rem;
  border-radius: 4px;
}
.subtotal b:first-child {
  font-weight: 600;
}
.subtotal b:last-child {
  font-weight: 800;
  color: var(--primary);
}
.subtotal-info {
  display: flex;
  font-weight: 600;
}
.subtotal-info span {
  color: var(--error);
  font-size: 0.65rem;
  margin-left: 0.25rem;
}

.comment-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}
.comment-container p {
  display: flex;
  font-weight: 600;
  font-family: 'Inter';
  gap: 0.5rem;
  align-items: center;
}
.comment-container p span {
  font-weight: 400;
  color: var(--grey);
}

.comment {
  padding: 1rem 2rem;
  font-size: 0.85rem;
}
.comment b {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.comment p {
  padding-left: 1rem;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  padding: 1rem;
}
