.separator {
  width: 100%;
  display: flex;
  height: 1.5px;
  background: var(--primary);
  position: relative;
}

.separator.square::before {
  content: '';
  position: absolute;
  display: block;
  transform: translate(-50%, -50%) rotate(45deg);
  background: var(--primary);
  height: 0.5rem;
  width: 0.5rem;
}
