.timer {
  display: flex;
  align-items: center;
}

.timer .counter {
  font-size: 2.5rem;
  display: flex;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  background: var(--tint_1);
  color: var(--white);
  width: 80%;
  justify-content: space-between;
  margin: auto;
}
