.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.container * {
  font-family: 'Inter';
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
