.header {
  width: 95%;

  display: grid;
  align-items: center;
  background-color: #e9e9e9;
  color: #1e1e1e;
  font-weight: bold;

  grid-template-columns: 0.5fr repeat(5, 1fr);
  justify-items: center;
  padding: 0.75rem;
  border-radius: 8px;
}

@media screen and (max-width: 480px) {
  .header {
    grid-template-columns: repeat(3, 1fr);
  }
}
