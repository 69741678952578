.container {
  width: 55%;
  font-family: 'Inter';
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
}

.answers-list {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 75dvh;
  overflow-y: scroll;
}

.table-heading {
  font-size: 1.25rem;
}
.requested-quantity {
  display: flex;
  gap: 0.5rem;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.answers-info {
  background: var(--info);
  border-radius: 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1rem;
}


@media only screen and (max-width: 480px) {
  .heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    
    div:nth-of-type(1) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }
}