.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 50dvh;
}

.container img {
  max-width: 100%;
}
