.custom-checkbox input {
  display: none;
}

.custom-checkbox input + label::before {
  content: '';
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid var(--grey);
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-bottom: 3px;
  border-radius: 4px;
}

.custom-checkbox.small input + label::before {
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--grey);
}

.custom-checkbox input:checked + label::before {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='14' viewBox='0 0 17 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.2452 1.35408C16.3725 1.48125 16.4735 1.63225 16.5424 1.79847C16.6113 1.96469 16.6467 2.14286 16.6467 2.32279C16.6467 2.50273 16.6113 2.6809 16.5424 2.84712C16.4735 3.01333 16.3725 3.16434 16.2452 3.2915L6.66076 12.8759C6.53359 13.0032 6.38259 13.1042 6.21637 13.1731C6.05015 13.242 5.87198 13.2775 5.69205 13.2775C5.51211 13.2775 5.33394 13.242 5.16772 13.1731C5.0015 13.1042 4.8505 13.0032 4.72333 12.8759L0.615729 8.76831C0.358811 8.51139 0.214478 8.16294 0.214478 7.7996C0.214478 7.43626 0.358811 7.08781 0.615729 6.83089C0.872647 6.57397 1.2211 6.42964 1.58444 6.42964C1.94778 6.42964 2.29623 6.57397 2.55315 6.83089L5.69205 9.9715L14.3077 1.35408C14.4349 1.22678 14.5859 1.12579 14.7521 1.05689C14.9184 0.987982 15.0965 0.952515 15.2765 0.952515C15.4564 0.952515 15.6346 0.987982 15.8008 1.05689C15.967 1.12579 16.118 1.22678 16.2452 1.35408Z' fill='black'/%3E%3C/svg%3E");
}

.custom-checkbox.green input:checked + label::before {
  background: var(--secondary);
  border-color: var(--secondary);
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='14' viewBox='0 0 17 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.2452 1.35408C16.3725 1.48125 16.4735 1.63225 16.5424 1.79847C16.6113 1.96469 16.6467 2.14286 16.6467 2.32279C16.6467 2.50273 16.6113 2.6809 16.5424 2.84712C16.4735 3.01333 16.3725 3.16434 16.2452 3.2915L6.66076 12.8759C6.53359 13.0032 6.38259 13.1042 6.21637 13.1731C6.05015 13.242 5.87198 13.2775 5.69205 13.2775C5.51211 13.2775 5.33394 13.242 5.16772 13.1731C5.0015 13.1042 4.8505 13.0032 4.72333 12.8759L0.615729 8.76831C0.358811 8.51139 0.214478 8.16294 0.214478 7.7996C0.214478 7.43626 0.358811 7.08781 0.615729 6.83089C0.872647 6.57397 1.2211 6.42964 1.58444 6.42964C1.94778 6.42964 2.29623 6.57397 2.55315 6.83089L5.69205 9.9715L14.3077 1.35408C14.4349 1.22678 14.5859 1.12579 14.7521 1.05689C14.9184 0.987982 15.0965 0.952515 15.2765 0.952515C15.4564 0.952515 15.6346 0.987982 15.8008 1.05689C15.967 1.12579 16.118 1.22678 16.2452 1.35408Z' fill='white'/%3E%3C/svg%3E");
}
.custom-checkbox.lightgreen input:checked + label::before {
  background: var(--shade_1);
  border-color: var(--shade_1);
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='14' viewBox='0 0 17 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.2452 1.35408C16.3725 1.48125 16.4735 1.63225 16.5424 1.79847C16.6113 1.96469 16.6467 2.14286 16.6467 2.32279C16.6467 2.50273 16.6113 2.6809 16.5424 2.84712C16.4735 3.01333 16.3725 3.16434 16.2452 3.2915L6.66076 12.8759C6.53359 13.0032 6.38259 13.1042 6.21637 13.1731C6.05015 13.242 5.87198 13.2775 5.69205 13.2775C5.51211 13.2775 5.33394 13.242 5.16772 13.1731C5.0015 13.1042 4.8505 13.0032 4.72333 12.8759L0.615729 8.76831C0.358811 8.51139 0.214478 8.16294 0.214478 7.7996C0.214478 7.43626 0.358811 7.08781 0.615729 6.83089C0.872647 6.57397 1.2211 6.42964 1.58444 6.42964C1.94778 6.42964 2.29623 6.57397 2.55315 6.83089L5.69205 9.9715L14.3077 1.35408C14.4349 1.22678 14.5859 1.12579 14.7521 1.05689C14.9184 0.987982 15.0965 0.952515 15.2765 0.952515C15.4564 0.952515 15.6346 0.987982 15.8008 1.05689C15.967 1.12579 16.118 1.22678 16.2452 1.35408Z' fill='white'/%3E%3C/svg%3E");
}

.custom-checkbox.small input:checked + label::before {
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 17 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.2452 1.35408C16.3725 1.48125 16.4735 1.63225 16.5424 1.79847C16.6113 1.96469 16.6467 2.14286 16.6467 2.32279C16.6467 2.50273 16.6113 2.6809 16.5424 2.84712C16.4735 3.01333 16.3725 3.16434 16.2452 3.2915L6.66076 12.8759C6.53359 13.0032 6.38259 13.1042 6.21637 13.1731C6.05015 13.242 5.87198 13.2775 5.69205 13.2775C5.51211 13.2775 5.33394 13.242 5.16772 13.1731C5.0015 13.1042 4.8505 13.0032 4.72333 12.8759L0.615729 8.76831C0.358811 8.51139 0.214478 8.16294 0.214478 7.7996C0.214478 7.43626 0.358811 7.08781 0.615729 6.83089C0.872647 6.57397 1.2211 6.42964 1.58444 6.42964C1.94778 6.42964 2.29623 6.57397 2.55315 6.83089L5.69205 9.9715L14.3077 1.35408C14.4349 1.22678 14.5859 1.12579 14.7521 1.05689C14.9184 0.987982 15.0965 0.952515 15.2765 0.952515C15.4564 0.952515 15.6346 0.987982 15.8008 1.05689C15.967 1.12579 16.118 1.22678 16.2452 1.35408Z' fill='white'/%3E%3C/svg%3E");
}

.custom-checkbox input:disabled + label {
  color: var(--grey);
}
