.item-quotation-list {
  display: flex;
  /* width: 47.3125rem; */
  width: 100%;
  padding: 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  border-radius: 0.5rem;
  background: #eef0f2;
  box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, 0.25);

  text-align: center;
  font-size: 1.125rem;
  line-height: 130%;
}

.product-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.product-info span {
  font-family: 'Montserrat';
}

.product-info .info {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  flex: 1 0 0;
  font-family: 'Montserrat';
}

.not-offer {
  font-weight: 700;
  color: var(--error);
}

/* mobile */

.item-quotation-list.mobile {
  display: flex;
  /* width: 22.875rem; */
  width: 100%;
  padding: 1.5rem 0.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
}
