.container {
  width: 95%;
  margin: 2rem auto 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
}
