.container {
  display: flex;
  background: linear-gradient(
    90deg,
    var(--silver) 25%,
    var(--white) 37%,
    var(--grey) 63%
  );
  background-size: 200% 100%;
  animation: loading-animation 1s ease infinite;
}
.rect {
  width: 100%;
  height: 3rem;
  border-radius: 8px;
}
.img {
  aspect-ratio: 1;
  width: 170px;
  border-radius: 8px;
}

@keyframes loading-animation {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0% 0;
  }
}
