.header {
  margin: 0 auto;
  height: 150px;
  padding: 3.5rem 4rem 0rem 4rem;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.services input[type='checkbox'] {
  all: unset;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header > nav {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar_mainContent {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 3.5rem;
  margin-right: 2rem;
  align-items: start;
}

.navbar_mainContent > button {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 1.6rem;
  background-color: transparent;
  border: 0;
}

.services * {
  font-family: 'Bebas Neue', sans-serif;
}
.services {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -0.5rem;
  z-index: 1;
}
.services img {
  display: none;
}
.services-text {
  cursor: pointer;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem 1rem;
  width: 100%;
  gap: 0.5rem;

  border-radius: 10px;
}

.services:hover {
  margin-bottom: -4px;
}
.services:hover .services-text {
  background: var(--silver);
  border-bottom: 4px solid var(--secondary);
}

.services:hover .services-text img {
  transform: rotate(180deg);
}

.services:hover .services-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-list {
  display: none;
  list-style: none;

  width: 100%;

  position: absolute;
  top: 100%;
  left: 0;

  background-color: var(--tint_5);
  border-radius: 4px;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.services-list li {
  font-size: 1.6rem;
  width: 80%;
  text-align: center;
  border-radius: 4px;
}
.services-list li:hover {
  background: var(--secondary);
  color: var(--white);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.8rem;
  width: 100%;
  gap: 1rem;
}

.header-mobile > button {
  background-color: transparent;
  border: 0;
}

.logo-icon {
  width: 16rem;
}

.listIcon {
  visibility: hidden;
  display: none;
}

.header-mobile {
  display: none;
}

.ovelaySideBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000080;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 999;
}

.wrapperSideBar {
  background-color: #ffffff;
  width: 50%;
  height: 100%;
}

.wrapperSideBar > nav,
.navBar {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}

.navBar > button {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 1.1rem;
  background-color: transparent;
  border: 0;
  list-style-type: none;
  margin-top: 2rem;
}

@media screen and (max-width: 1000px) {
  .header {
    display: flex;
    justify-content: center;
    padding: 1rem 0rem 0rem;
  }

  .header > nav {
    display: none;
    visibility: hidden;
  }

  .listIcon {
    visibility: visible;
    display: block;
    height: 2.5rem;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .header {
    padding: 1rem 1rem 0rem 1rem;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .navbar_mainContent > button {
    font-size: 1.2rem;
  }
}

@media screen and (max-height: 800px) {
  .main {
    padding: 80px 0 0 0;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.4rem;
    width: 100%;
  }

  .listIcon {
    height: 2.5rem;
  }

  .ovelaySideBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000080;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 999;
  }

  .wrapperSideBar {
    background-color: #ffffff;
    width: 50%;
    height: 100%;
  }

  .wrapperSideBar > nav,
  .navBar {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    align-items: center;
  }

  .navBar {
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  .header {
    height: 30px;
  }

  .listIcon {
    height: 1.8rem;
  }

  .ovelaySideBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #00000080;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 999;
  }

  .wrapperSideBar {
    background-color: #ffffff;
    width: 50%;
    height: 100%;
  }
}

@media screen and (max-width: 480px) {
  .header {
    width: 100%;
    height: auto;
    margin: 0;
    position: fixed;
    border-radius: 0;
    z-index: 890;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 1rem;
  }

  .wrapperSideBar > nav,
  .navBar {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
  }

  .navBar > button {
    font-size: 1.5rem;
    background: var(--silver);
    width: 80%;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 4px;
  }

  .logo-icon {
    display: none;
  }

  .header-mobile {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 0.5rem;
    align-items: center;
  }
  .logo-brand {
    width: 3rem;
  }
  .logo-text {
    width: 12rem;
  }

  .services {
    margin-top: 2rem;
    background: var(--silver);
    width: 80%;
    padding: 0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }
  .services img {
    display: block;
  }

  .services-list {
    position: static;
    transition: all 0.2s ease;
  }
  .services-text {
    border-bottom: none;
    transition: all 0.2s ease;
  }
  .services input[type='checkbox']:checked ~ .services-text {
    border-bottom: 4px solid var(--secondary);
  }
  .services input[type='checkbox']:checked ~ .services-text img {
    transform: rotate(180deg);
  }
  .services-list {
    display: none;
  }
  .services input[type='checkbox']:checked ~ .services-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .services-text,
  .services-list li {
    position: sticky;
    font-size: 1.5rem;
  }
  .services-list li {
    z-index: 1;
  }
}
