.observation {
  margin-top: 2rem;
}

.label {
  font-size: 1.25rem;
  font-weight: 600;
}

.wrapButtonActionFooter{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1rem;
}

.pulse > div > button{
  animation: pulse infinite 1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.wrapProductTableRow > button {
  display: none;
}

.rowEdit > div > div:nth-of-type(1) {
  display: none;
}
.rowEdit> button {
  display: flex !important;
}

.rowEdit {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;

  div  {
    grid-template-columns: repeat(3 ,1fr)!important;
  }
}

@media only screen and (max-width: 480px) {
  .containerListImages > div > div:nth-of-type(2) > button:nth-of-type(1) {
    display: none;
  }
}