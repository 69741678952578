.container {
  font-family: 'Inter';
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.row-field {
  font-size: 1rem;
  padding: 0.5rem 0;
}
.row-field:last-child {
  padding-right: 2rem;
}
.container ul {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.list-header {
  display: grid;
  grid-template-columns: 2fr 2fr 1.5fr 1.45fr;
  font-weight: 700;
  padding: 0.5rem 0 0.5rem 2rem;
  background: var(--tint_3);
  font-size: 0.875rem;
}
.list-header span {
  text-align: center;
}

.notOffer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Bebas Neue';
  font-size: 1.3rem;
  font-style: italic;
  color: var(--error);
}
