
.contentButton {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  
}

.contentButton > button:first-child{
  position: relative; 
  padding: 0.8rem 0;
}

/* .contentButton > button:first-child > svg:last-child{
    position: absolute;
    top: 50%;
    right: 25px;
    transform:  translateY(-50%) rotate(90deg);
    
} */

.rotation{
  transform-origin: left;
  transform:  translateX(50%) translateY(0%) rotate(-90deg) !important;
  transition: transform 0.6s ease;
}

.containerDropDownOpen{
  width: 100%;
  button {
    width: 100%;
    background-color: #e8f5e9;
    color: #0f5529;
    padding: 0.8rem 0;
  }
}

.created{
  button:nth-of-type(1){
    display: none;
  }
}

.replies{
  button:nth-of-type(2){
    display: none;
  }
}

/* .blocked{
  button:nth-of-type(3){
    display: none;
  }
} */

.completed{
  button:nth-of-type(3){
    display: none;
  }
}
