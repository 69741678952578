.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
}

.message {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  color: #1e1e1e;
  font-size: 2rem;
}
