.container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.customSelect {
  position: relative;
  cursor: pointer;
}

.customSelectTrigger {
  background: #eef1f6;
  color: var(--black);
  border: 1px solid #ccc;
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 4px;

  display: flex;
  height: 3rem;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: var(--2s-scale-10, 0.625rem);
}

.arrow {
  display: flex;
  align-items: center;
}

.customOptions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: #eef1f6;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  border-radius: 0.5rem;
  overflow: hidden;
}

.customOption {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  background: #eef1f6;
  color: var(--black);
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 0.5rem;
}

.customOption:hover {
  background: var(--tint_1);
  color: #fff;
}
