.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.last-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .wrapProductTableRow > div > div:nth-of-type(4) > div > button {
    display: none;
  }
}
