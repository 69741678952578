.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f7fa;
  gap: 2rem;
  padding-bottom: 2rem;
}
.header {
  background: #fff;
  width: 100%;
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  overflow: hidden;
}
.logo-wrapper {
  width: 95%;
}
.logo {
  margin-left: 2rem;
  width: 16rem;
}
.statistics {
  display: flex;
  gap: 2.5rem;
  align-items: center;
  position: relative;
}
.statistics::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.statistics .pattern {
  right: 0;
  top: 75%;
  width: 435px;
}
.statistics-wrapper {
  display: flex;
  gap: 1rem;
}
.pattern {
  position: absolute;
  bottom: 0;
  right: 0;
}
.actions {
  width: calc(95% - 2rem);
  border-radius: 40px;
  margin: auto 0;
  padding: 3rem 0 6rem;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 250px));
  gap: 3rem;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.actions .pattern {
  left: 0;
  bottom: 0%;
  width: 435px;
}

.pattern.mobile {
  display: none;
}

.singout {
  all: unset;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .main {
    gap: 0;
  }
  .header {
    flex-direction: column;
    overflow: visible;
    gap: 1.5rem;
    border-radius: 0;
  }
  .header .logo-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .header .logo {
    width: 245px;
    margin: 0 auto;
  }

  .statistics {
    max-width: 100%;
    overflow-x: auto;
  }

  .actions .pattern,
  .statistics .pattern {
    display: none;
  }
  .statistics-wrapper {
    padding: 0 1rem;
  }
  .actions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    border-radius: 0;
  }
  .pattern.mobile {
    display: block;
    left: -10%;
    bottom: -20%;
    width: 200px;
    z-index: 0;
  }
}

@media screen and (min-width: 1920px) {
  .actions {
    padding: 3rem 1.5rem 6rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 375px));
  }
}
