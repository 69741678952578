.circle {
  display: block;
  width: 1rem;
  height: 1rem;
  background: var(--primary);
  border-radius: 50%;
}
.notification {
  background: var(--error);
}

.modified {
  background: var(--warning);
}

.disabled {
  background: var(--grey);
}

.pulse {
  animation: pulse infinite 1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
