.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.body {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}
