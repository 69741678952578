.companies-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1.5rem;
}

.companies-container input {
  font-weight: 600;
}

.searchOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.companies-list {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey);
  border-radius: 4px;
  height: 455px;
  max-height: 455px;
  overflow: auto;
}

.companies-list li {
  list-style: none;
  padding: 0.75rem 0.5rem;
}
.companies-list li label {
  font-family: 'Inter';
  font-weight: 600;
  color: #333;
}

.companies-list li.checked {
  background-color: var(--tint_5);
}

@media only screen and (max-width: 480px) {
  .companies-container {
    height: 100%;
  }
}
